<template>
	<templateComp :apiUrl="apiUrl" :inf="inf" :kind="kind" :pr="ths" :searchKeyOpts="searchKeyOpts" />
</template>

<script>
import templateComp from 'pages/template'

export default {
	components: { templateComp },
	data() {
		return {
			kind: 'talk',
			searchKeyOpts: [
				{ label: '메시지key', value: 'messageKey' },
				{ label: '템플릿코드', value: 'templateCode' },
				{ label: '제목', value: 'subject' },
				{ label: '내용', value: 'content' },
			],
			inf: [
				{ title: 'seq', model: 'idx', size: 2 },
				{ title: '메시지 key', key: 'messageKey', size: 2 },
				{ title: '템플릿 코드', key: 'templateCode', size: 6 },
				{ title: '제목', key: 'subject', size: 6 },
				{ title: '내용', key: 'content', size: 16, notCenter: 1 },
				{ title: '카카오버튼 구분', key: 'kakaoButtonType', size: 3 },
				{ title: '카카오버튼 내용', key: 'kakaoButtonInfo', size: 8, notCenter: 1 },
				{ title: '생성일', key: 'createDt', size: 4 },
				{ title: '수정일', key: 'updateDt', size: 4 },

				{
					title: '수정',
					model: 'button',
					icon: 'pencil-square',
					size: 2,
					func: item => {
						this.templateComp.templatePopup.open(this.kind, true, item)
					},
				},
			],
			apiUrl: '/operatingSelect/sendMessageList',
		}
	},
	methods: {},
	created() {},
}
</script>
